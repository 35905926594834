import React from 'react';
import { motion } from 'framer-motion';
import Button from './Button';

const BaseForm = ({ title, onClose, children, isLoading }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
        className="bg-white p-8 rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto relative scrollbar-thin scrollbar-thumb-navy-blue scrollbar-track-gray-200"
        onClick={(e) => e.stopPropagation()}
      >
        <h2
          style={{
            backgroundColor: 'rgba(241, 103, 100, 1)',
          }}
          className="absolute top-0 left-0 text-2xl font-bold min-w-full mb-2 font-roboto text-gray-800 text-center py-4 px-6"
        >
          {title}
        </h2>
        <div className="pt-16">{children}</div> {/* Added padding-top to move content below the title */}
        {isLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500"></div>
          </div>
        )}
        <Button variant="secondary" onClick={onClose} className="absolute top-2 right-2 bg-slate-100 border-gray-600 shadow font-bold hover:bg-orange-200 rounded-full">
          ✕
        </Button>
      </motion.div>
    </motion.div>
  );
};

export default BaseForm;
