import React from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import venueBgImage from "/sponsorship.jpg";

const VenuePage = () => {
  // You'll need to replace these with actual venue images
  const venueImages = [
    "",
    "",
    "",
    "",
  ];

  return (
    <div className="relative">
      <PageHeader 
        title="THE VENUE" 
        description="The Lagos Oriental Hotel, Lagos, Nigeria"
        backgroundImage={venueBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 tracking-wider text-custom-base">Our Venue</h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {venueImages.map((image, index) => (
                <div key={index} className="div-with-bold-shadow p-4">
                  <img src={image} alt={`Venue image ${index + 1}`} className="w-full h-auto rounded-lg" />
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default VenuePage;