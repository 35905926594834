import React, { useState } from 'react';
import BaseForm from './BaseForm';
import axios from 'axios';

const MasterClassEnrollmentForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    occupation: '',
    company: '',
    experience: '',
    interests: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`https://africafilmfinanceforum.com/api/masterclass-enrollment`, formData);
      setSuccess('Thank you for enrolling in the Master Class. We will contact you with further details soon.');
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      setError('Enrollment failed. Please try again later.');
      console.error('Master Class enrollment error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="Master Class Enrollment" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(formData).map(([key, value]) => (
          <div key={key}>
            <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
            </label>
            {key === 'experience' || key === 'interests' ? (
              <textarea
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                rows="3"
                className="form-input w-full"
                required
              />
            ) : (
              <input
                type={key === 'email' ? 'email' : key === 'phone' ? 'tel' : 'text'}
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                className="form-input w-full"
                required
              />
            )}
          </div>
        ))}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            CANCEL
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'ENROLLING...' : 'ENROLL'}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default MasterClassEnrollmentForm;