import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import FilmScreeningForm from '../component/FilmScreeningForm';
import filmScreeningBgImage from "../assets/film-screening-bg.jpg"; // Make sure this image exists

const FilmScreeningPage = () => {
  const [showScreeningForm, setShowScreeningForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="FILM SCREENING" 
        description="Experience a diverse array of African and international films at AFFF."
        backgroundImage={filmScreeningBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">FILM SCREENING</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              The Africa Film Finance Forum (AFFF) will present a comprehensive film screening program in the Main
              Hall throughout its three-day event, designed to host up to 3,000 attendees. This extensive program will
              feature a broad array of film content, showcasing not only African film projects but also highlighting a
              diverse selection of international films and trailers.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              The screenings will offer a unique opportunity to experience a wide range of cinematic works, reflecting
              various genres, styles, and cultural perspectives from around the globe. By bringing together this diverse
              collection of films, the AFFF aims to celebrate the global film landscape, foster cross-cultural dialogue,
              and provide attendees with an enriching and varied cinematic experience.
            </p>
            <button 
              onClick={() => setShowScreeningForm(true)}
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
            >
              PROMOTE YOUR PROJECT
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showScreeningForm && (
          <FilmScreeningForm onClose={() => setShowScreeningForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default FilmScreeningPage;