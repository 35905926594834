import React from "react";

const SpeakersCard = ({ id, name, image, title, company, isActive, backgroundColor }) => {
  const imageUrl = `/speakers/${id.toString().padStart(2, '0')}.png`;

  return (
    <div className={`transition-all duration-300 ${isActive ? 'scale-105 md:scale-110 z-10' : 'scale-100'} px-1 md:px-2`}>
      <div 
        className={`relative ${isActive ? 'w-[280px] h-[280px] md:w-[400px] md:h-[400px]' : 'w-[220px] h-[270px] md:w-[270px] md:h-[331px]'} rounded-md overflow-hidden`} 
        style={isActive ? {} : { backgroundColor }}
      >
        <div 
          className={`absolute inset-0 ${isActive ? 'rounded-[2px_100px_100px_100px] md:rounded-[2px_200px_200px_200px] pr-4 md:pr-8' : ''} overflow-hidden`}
          style={isActive ? {
            background: 'linear-gradient(to bottom right, #5EBAB0, rgba(241, 103, 100, 1))'
          } : {}}
        >
          <img
            src={imageUrl}
            alt={`${name} - speaker`}
            className={`object-cover object-center transition-all duration-300 ${
              isActive ? 'w-[260px] h-[317px] md:w-[380px] md:h-[463px]' : 'w-[220px] h-[270px] md:w-[270px] md:h-[331px]'
            }`}
          />
        </div>
      </div>
      {isActive && (
        <div className="mt-2 md:mt-4 text-center">
          <p className="text-base md:text-[1.2rem] font-semibold font-roboto">{name}</p>
          <p className="text-[#F16764] font-semibold text-xs md:text-[0.8rem] leading-tight mt-1">
            {title}
          </p>
          <p className="text-[#F16764] font-rand font-semibold text-xs md:text-[0.8rem] leading-tight mt-1">
            {company}
          </p>
        </div>
      )}
    </div>
  );
};

export default SpeakersCard;