import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PricingModal from "../component/PricingModal";
import dealTableBgImage from "../assets/deal-table.jpg";
import { Icon } from '@iconify/react';

const DealTablePage = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  return (
    <div className="relative min-h-screen flex flex-col">
      <PageHeader 
        title="THE DEAL TABLE" 
        description="An ultra-exclusive boardroom for high-budget film projects with substantial ROI potential."
        backgroundImage={dealTableBgImage}
      />
      <main className="flex-grow bg-white p-4 sm:p-6 flex items-center justify-center mb-32">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <section className="font-lexend text-center">
            <p className="mb-8 text-lg sm:text-xl leading-relaxed">
              The Deal Table is an ultra-exclusive boardroom designed for high-budget film projects with substantial
              ROI potential. Access is limited to Premium Elite Plus financiers and top Sales & Production companies. It
              offers a sophisticated environment for high-level networking, in-depth negotiations, and strategic
              discussions, making it the ideal setting for securing significant investments and forging elite business
              partnerships.
            </p>
            <div className="flex justify-center mb-8">
              <Icon icon="mdi:handshake-outline" className="text-[7rem] text-custom-red mr-4" />
              <Icon icon="mdi:movie-open-outline" className="text-[7rem] text-custom-red" />
            </div>
            <button 
              onClick={() => setIsPricingModalOpen(true)}
              className="bg-custom-red text-white px-8 py-4 text-center font-bold font-roboto text-lg rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 shadow-lg hover:shadow-xl"
            >
              GET YOUR BADGE NOW
            </button>
          </section>
        </div>
      </main>
      <Footer />
      <PricingModal isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
    </div>
  );
};

export default DealTablePage;