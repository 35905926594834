// EventCards.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const EventCards = ({ cardData }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
      {cardData.map((item, index) => (
        <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
          <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
          <div className="p-4 sm:p-6">
            <h3 className="font-roboto text-xl sm:text-2xl font-semibold mb-2 sm:mb-3 text-gray-800">{item.title}</h3>
            <p className="text-gray-600 mb-4 text-sm sm:text-base">{item.content}</p>
            <Link to={item.link} className="bg-custom-red text-white px-4 sm:px-6 py-2 rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 text-sm sm:text-base font-roboto inline-block">LEARN MORE</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventCards;