import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Announcement = ({ announcements = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (announcements.length === 0) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change announcement every 5 seconds

    return () => clearInterval(timer);
  }, [announcements.length]);

  const nextAnnouncement = () => {
    if (announcements.length === 0) return;
    setCurrentIndex((prevIndex) => 
      prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevAnnouncement = () => {
    if (announcements.length === 0) return;
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? announcements.length - 1 : prevIndex - 1
    );
  };

  if (announcements.length === 0) {
    return null;
  }

  const currentAnnouncement = announcements[currentIndex];

  return (
    <div className="relative bg-[#1e2833] text-white flex flex-col md:flex-row">
      <div className="w-full md:w-1/2">
        <img 
          src={currentAnnouncement.image} 
          alt="Announcement" 
          className="w-full h-64 md:h-full object-cover"
        />
      </div>
      <div className="w-full md:w-1/2 p-6 md:p-16 flex flex-col justify-between">
        <h2 className="font-header text-2xl md:text-[3rem] tracking-widest mb-4">ANNOUNCEMENTS</h2>
        <div>
          <h3 className="text-xl md:text-2xl font-roboto text-orange-200 font-extralight mb-4">{currentAnnouncement.title}</h3>
          <p className="font-exo text-sm md:text-md mb-6">{currentAnnouncement.description}</p>
          <Link 
            to={currentAnnouncement.link} 
            className="bg-transparent border-2 border-white text-white py-2 px-4 uppercase font-bold tracking-wider inline-block text-sm md:text-base"
          >
            {currentAnnouncement.ctaText}
          </Link>
        </div>
        <div className="flex justify-end mt-8">
          <button onClick={prevAnnouncement} className="mr-4 rounded-full border-white">
            <ArrowLeft size={20} />
          </button>
          <button onClick={nextAnnouncement}>
            <ArrowRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Announcement;