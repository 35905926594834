import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import exhibitBgImage from "/exhibition.jpg";

const HowToExhibitPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const exhibitionOptions = [
    {
      name: "Premium Entrance Space",
      location: "Positioned prominently at the entrance of the hall",
      description: "This high-visibility spot guarantees maximum exposure as attendees enter the event. It's ideal for capturing immediate attention and making a strong first impression.",
      features: "Includes prime signage placement and high foot traffic, perfect for showcasing key messages and attracting visitors right from the start."
    },
    {
      name: "Cubic Exhibit Space",
      location: "Located within the main exhibition area",
      description: "A versatile cubic space designed for an interactive and immersive experience. Ideal for detailed presentations and engaging one-on-one interactions.",
      features: "Offers a flexible layout to accommodate various display needs, with options for customization, branding, and interactive elements."
    },
    {
      name: "Prime Location Space",
      location: "Situated near the front of the exhibit hall, but not directly at the entrance",
      description: "This prominent area ensures high visibility and easy access, making it perfect for engaging with attendees who are actively exploring the exhibits.",
      features: "Excellent placement with good visibility, allowing for effective engagement with visitors who are navigating through the main areas of the hall."
    },
    {
      name: "Value Space",
      location: "Located towards the rear of the exhibit hall",
      description: "An affordable option that still offers excellent visibility and engagement potential. This space provides a cost-effective solution for exhibitors who want to make an impact without the premium price.",
      features: "Provides a great opportunity for meaningful interactions and steady foot traffic from visitors exploring the hall, making it a smart choice for budget-conscious exhibitors."
    }
  ];

  const promotionalOptions = [
    {
      name: "Film Screening",
      description: "Showcase your film to a captivated audience during dedicated screening sessions at AFFF. This is an exceptional opportunity to present your work to industry professionals, potential investors, and film enthusiasts in a high-profile setting.",
      benefits: "Gain exposure and valuable feedback from a diverse audience, including key decision-makers in the film industry. It's also a chance to generate buzz and build interest around your project."
    },
    {
      name: "Advertising Opportunities",
      description: "Maximize your visibility at AFFF through various advertising options. Choose from a range of placements including event programs, digital displays, and on-site banners.",
      benefits: "Reach attendees effectively by positioning your brand in high-traffic areas and prominent locations throughout the event. Tailor your advertising to highlight your projects or services and enhance your presence among industry leaders and potential partners."
    }
  ];

  return (
    <div className="relative font-lexend">
      <PageHeader 
        title="HOW TO EXHIBIT" 
        description="Discover the various exhibition options and promotional opportunities at AFFF."
        backgroundImage={exhibitBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">EXHIBITION OPTIONS</h2>
            </div>
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-lg p-6 overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left font-bold">Option</th>
                    <th className="p-3 text-left font-bold">Location</th>
                    <th className="p-3 text-left font-bold">Description</th>
                    <th className="p-3 text-left font-bold">Features</th>
                  </tr>
                </thead>
                <tbody>
                  {exhibitionOptions.map((option, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                      <td className="p-3 font-semibold">{option.name}</td>
                      <td className="p-3">{option.location}</td>
                      <td className="p-3">{option.description}</td>
                      <td className="p-3">{option.features}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">OTHER PROMOTIONAL OPTIONS</h2>
            </div>
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg shadow-lg p-6 overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="p-3 text-left font-bold">Option</th>
                    <th className="p-3 text-left font-bold">Description</th>
                    <th className="p-3 text-left font-bold">Benefits</th>
                    <th className="p-3 text-left font-bold">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {promotionalOptions.map((option, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-blue-50"}>
                      <td className="p-3 font-semibold">{option.name}</td>
                      <td className="p-3">{option.description}</td>
                      <td className="p-3">{option.benefits}</td>
                      <td className="p-3">
                        <button 
                          onClick={() => setShowContactForm(true)}
                          className="bg-custom-red text-white px-4 py-2 rounded font-bold text-sm hover:bg-red-600 transition duration-300"
                        >
                          EXHIBIT YOUR WORK
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default HowToExhibitPage;