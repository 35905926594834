import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import whoShouldAttendBgImage from "/who-attend.webp";
import GetBadgeModal from '../component/GetBadgeModal';

const WhoShouldAttendPage = () => {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const attendeeCategories = [
    {
      title: "Financiers",
      items: [
        "Venture Capitalists", "Angel Investors", "Film Financing Institutions",
        "Financial institutions", "Brands that supports films",
        "Investors from other industries e.g. Oil & Gas, Insurance Companies etc.",
        "Private Equity Firms", "Government Agencies and Grants Bodies",
        "Film and Media Investment Funds"
      ]
    },
    {
      title: "Film Industry Professionals",
      items: [
        "Film Producers", "Film Directors", "Screenwriters", "Film Distributors",
        "Film Sales Agents", "Film Production Companies",
        "Film Marketing and PR Professionals", "Film Exhibitors and Theaters"
      ]
    },
    {
      title: "Industry Partners",
      items: [
        "Film and Media Associations", "Film Studios", "Media Companies",
        "Creative Agencies", "Technology Providers for Film Production and Distribution"
      ]
    },
    {
      title: "Media Partners",
      items: [
        "Journalists and Film Critics", "Film Bloggers and Influencers",
        "Media Outlets (TV, Radio, Online Platforms)"
      ]
    },
    {
      title: "Event Participants",
      items: [
        "Attendees of Pitch Sessions", "Participants in Masterclasses",
        "Visitors to Exhibitions and Expos", "Delegates at Networking Sessions"
      ]
    },
    {
      title: "Sponsorship and Marketing Representatives",
      items: [
        "Corporate Sponsors", "Marketing and Advertising Agencies"
      ]
    },
    {
      title: "Educational and Training Institutions",
      items: [
        "Film Schools and Universities",
        "Professional Training Organizations in Film and Media"
      ]
    },
    {
      title: "Government and Policy Makers",
      items: [
        "Representatives from Film Commissions",
        "Policy Makers in Media and Culture"
      ]
    },
    {
      title: "General Public Interested in Film",
      items: [
        "Film Enthusiasts", "Students and Academics in Film Studies"
      ]
    }
  ];


  const badgeCategories = [
    {
      category: "FINANCIERS",
      badges: [
        {
          name: "PREMIUM ELITE PLUS FINANCIER BADGE",
          price: 1500,
          details: "(3 BADGES (50% DISCOUNTED PRICE) AND OWN AN INVESTORS ROOM (Investors Room price - $1,200))",
          features: [
            "Access to the Deal Table",
            "Access to the Investors Lounge",
            "Own an Investors Room",
            "Access to the Market Review session",
            "Access to the master class",
            "AFFF Forum session"
          ],
          badgeCount: 3,
          isAnnual: true
        },
        {
          name: "PREMIUM ELITE FINANCIER BADGE",
          price: 450,
          details: "(2 BADGES)",
          features: [
            "Access to the Deal Table",
            "Access Investors Lounge",
            "Access Investors Room",
            "Access to the Market Review session",
            "Access to the master class",
            "AFFF Forum session"
          ],
          badgeCount: 2,
          isAnnual: false
        },
        {
          name: "PREMIUM FINANCIER BADGE",
          price: 300,
          details: "(2 BADGES)",
          features: [
            "Access to the Investors Lounge",
            "Access to the Investors Room",
            "Access to the Market Review session",
            "Access to the master class",
            "AFFF Forum session"
          ],
          badgeCount: 2,
          isAnnual: false
        }
      ]
    },
    {
      category: "FILM INDUSTRY",
      badges: [
        {
          name: "PREMIUM ELITE PLUS FILM INDUSTRY BADGE",
          price: 450,
          details: "(2 BADGES)",
          features: [
            "Access to the Deal Table",
            "Access to the Investors Lounge",
            "Access to the Investors Room",
            "Access to the Market Review session",
            "Access to the master class",
            "AFFF Forum session"
          ],
          badgeCount: 2,
          isAnnual: false
        },
        {
          name: "PREMIUM FILM INDUSTRY BADGE",
          price: 300,
          details: "(2 BADGES)",
          features: [
            "Access to the Investors Lounge",
            "Access to the Investors Room",
            "Access to the Market Review session",
            "Access to the master class",
            "AFFF Forum session"
          ],
          badgeCount: 2,
          isAnnual: false
        }
      ]
    }
  ];

  const openModal = (badge) => {
    setSelectedBadge(badge);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBadge(null);
    setIsModalOpen(false);
  };

  return (
    <div className="relative bg-[#FAF0E6]">
      <PageHeader 
        title="WHO CAN ATTEND?" 
        description="The Africa Film Finance Forum (AFFF) aims to bring together a diverse group of participants from various industries."
        backgroundImage={whoShouldAttendBgImage}
      />
      <main className="mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6 mx-[6rem]">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12">
            <h2 className="font-header text-[4rem] sm:text-3xl md:text-[4rem] font-bold mb-4 tracking-wider text-custom-base">Attendee Categories</h2>
            
            {attendeeCategories.map((category, index) => (
              <div key={index} className="mb-16 sm:mb-12 div-with-bold-shadow flex-col p-6">
                <h3 className="font-roboto text-2xl sm:text-3xl font-bold mb-6">{category.title}</h3>
                <ul className="list-disc pl-5 text-sm sm:text-base">
                  {category.items.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </section>

          <section className="mb-8 sm:mb-12">
            <h2 className="font-header text-[5rem] sm:text-[3rem] md:text-[4rem] font-bold mb-4 tracking-wider text-custom-base">BADGES</h2>
            
            {badgeCategories.map((category, index) => (
              <div key={index} className="mb-8 sm:mb-12">
                <h3 className="font-roboto text-2xl sm:text-3xl font-bold mb-4 div-with-bold-shadow">{category.category}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {category.badges.map((badge, i) => (
                    <div key={i} className="bg-white rounded-lg shadow-lg overflow-hidden">
                      <div className="p-6">
                        <h4 className="text-xl font-bold mb-2">{badge.name}</h4>
                        <p className="text-3xl font-bold text-custom-red mb-2">${badge.price}</p>
                        <p className="text-sm mb-4">{badge.details}</p>
                        <ul className="mb-6">
                          {badge.features.map((feature, j) => (
                            <li key={j} className="flex items-center mb-2">
                              <FaCheck className="w-4 h-4 mr-2 text-green-500" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                        <button 
                          className="w-full bg-custom-red text-white py-2 rounded-lg font-bold hover:bg-red-600 transition duration-300"
                          onClick={() => openModal(badge)}
                        >
                          Get Badge
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </section>

          <section className="text-center mt-8">
            <p className="font-bold text-lg mb-4">ACCREDITATION PORTAL IS NOW OPENED</p>
            <Link to="/get-badges" className="bg-custom-red text-white px-6 py-3 rounded-lg font-bold hover:bg-red-600 transition duration-300 inline-block">
              GET YOUR BADGES
            </Link>
          </section>
        </div>
      </main>
      <Footer />

      <GetBadgeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        plan={selectedBadge}
      />
    </div>
  );
};

export default WhoShouldAttendPage;