import React, { useState } from "react";
import axios from "axios";
import PageHeader from "../component/PageHeader";
import contactBgImage from "/contact-bg.jpg";
import Footer from "./Footer";

function Contact() {
  const [formData, setFormData] = useState({
    subject: "General enquiries",
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`https://africafilmfinanceforum.com/api/contact`, formData);
      setSuccess("Thank you for reaching out! We've received your message and will get back to you shortly.");
      setFormData({
        subject: "General enquiries",
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Contact form submission error:", error);
      console.error("Error response:", error.response);
      setError(
        error.response?.data?.error || 
        error.response?.data?.details || 
        "Oops! Something went wrong. Please try again or contact us directly."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <PageHeader 
        title="CONNECT WITH US" 
        description="We're excited to hear from you! Whether you have questions about the Africa Film Finance Forum or want to explore collaboration opportunities, we're here to help."
        backgroundImage={contactBgImage}
      />  
      <main className="flex-grow bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12">
            {/* Contact Information */}
            <div className="lg:w-1/3 border-2 border-custom-red p-8 rounded-lg shadow-md bg-gray-50">
              <h2 className="text-2xl font-bold mb-6 text-custom-red">Get in Touch</h2>
              <p className="mb-6 text-gray-700">
                Thank you for your interest in the Africa Film Finance Forum 2024. We're looking forward to connecting with you!
              </p>
              <p className="mb-6 text-gray-700">
                For all inquiries, please reach out to our event organizers:
              </p>
              <div className="mb-6">
                <p className="font-bold text-lg text-gray-800">Okhma</p>
                <p className="text-gray-700">3b Bode Ajakaiye Road,</p>
                <p className="text-gray-700">Atlantic View Estate, Lekki,</p>
                <p className="text-gray-700">Lagos, NIGERIA</p>
              </div>
              <div className="text-gray-700">
                <p className="mb-2"><span className="font-semibold">Phone:</span> +234 803 565 7616, +234 814 655 8068</p>
                <p><span className="font-semibold">Email:</span> businessdev@okhma.com</p>
              </div>
            </div>

            {/* Contact Form */}
            <div className="lg:w-2/3 bg-white p-8 rounded-lg shadow-lg border border-gray-200">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Send Us a Message</h2>
              <p className="mb-6 text-gray-600">Have a specific question or proposal? Use the form below to reach out directly. We aim to respond within 24-48 hours.</p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                    Nature of Inquiry
                  </label>
                  <select
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                  >
                    <option value="General enquiries">General Enquiries</option>
                    <option value="sponsorship">Sponsorship Opportunities</option>
                    <option value="accreditation">Accreditation & Exhibition</option>
                    <option value="cinetour">CineTour Information</option>
                    <option value="deal-table">Deal Table Participation</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="5"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                    required
                    placeholder="Please provide details about your inquiry or how we can assist you."
                  ></textarea>
                </div>

                {error && <p className="text-red-500 bg-red-50 p-3 rounded-md">{error}</p>}
                {success && <p className="text-green-600 bg-green-50 p-3 rounded-md">{success}</p>}

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`px-6 py-3 bg-custom-red text-white font-medium rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red transition duration-150 ease-in-out ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;