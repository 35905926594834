import React from "react";
import { Link } from "react-router-dom";
import dealTableBackground from "../assets/deal-table.jpg";

const DealTable = () => {
  return (
    <section className="relative min-h-screen w-full overflow-hidden font-sans">
      {/* Background image */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${dealTableBackground})`,
        }}
      ></div>
      
      {/* Black overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      
      {/* Centered content */}
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 md:p-8 lg:p-10 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[60%] flex flex-col justify-center items-center text-white">
          <h2 className="font-header text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-center tracking-wider">WHERE VISION MEETS INVESTMENT</h2>
          <p className="text-center mb-6 text-sm md:text-base lg:text-lg xl:text-xl">
            The ultimate nexus for visionary filmmakers and discerning investors. 
            This exclusive space is designed to foster meaningful connections, 
            facilitate high-impact negotiations, and secure groundbreaking 
            partnerships. Join us to turn creative dreams into financial realities 
            and unlock the immense potential of Africa's film and creative 
            industries.
          </p>
          
          <Link 
            to="/exhibit/deal-table" 
            className="group bg-custom-red shadow-md text-white px-4 md:px-6 py-2 md:py-3 rounded-tl-lg rounded-br-lg font-bold text-lg md:text-xl lg:text-2xl flex items-center gap-2 transition duration-300 ease-in-out hover:bg-black hover:text-white"
          >
            Get On The Table
            <svg
              width="24"
              height="24"
              viewBox="0 0 50 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition duration-300 ease-in-out group-hover:fill-white"
            >
              <path
                d="M25 13L22.7656 15.1766L31.4844 23.9375H12.5V27.0625H31.4844L22.7656 35.7703L25 38L37.5 25.5L25 13Z"
                fill="currentColor"
              />
              <path
                d="M25 47.375C20.6735 47.375 16.4442 46.0921 12.8469 43.6884C9.24959 41.2848 6.44581 37.8683 4.79014 33.8712C3.13448 29.8741 2.70128 25.4757 3.54533 21.2324C4.38938 16.9891 6.47277 13.0913 9.53205 10.032C12.5913 6.97277 16.4891 4.88938 20.7324 4.04533C24.9757 3.20128 29.3741 3.63448 33.3712 5.29014C37.3683 6.94581 40.7848 9.74959 43.1884 13.3469C45.5921 16.9442 46.875 21.1735 46.875 25.5C46.8684 31.2996 44.5616 36.8597 40.4607 40.9607C36.3597 45.0616 30.7996 47.3684 25 47.375ZM25 6.75001C21.2916 6.75001 17.6665 7.84968 14.5831 9.90995C11.4996 11.9702 9.09641 14.8986 7.67727 18.3247C6.25813 21.7508 5.88681 25.5208 6.61029 29.158C7.33376 32.7951 9.11952 36.136 11.7418 38.7583C14.364 41.3805 17.7049 43.1663 21.3421 43.8897C24.9792 44.6132 28.7492 44.2419 32.1753 42.8228C35.6014 41.4036 38.5298 39.0004 40.5901 35.917C42.6503 32.8335 43.75 29.2084 43.75 25.5C43.7442 20.529 41.7669 15.7632 38.2519 12.2482C34.7368 8.7331 29.971 6.7558 25 6.75001Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        </div>
      </div>

      {/* The Deal Table title */}
      <div className="absolute inset-x-0 bottom-0 flex items-end justify-center">
        <h1 className="font-antonio text-[12vw] sm:text-[10vw] md:text-[8vw] font-extrabold text-white text-center leading-none mb-[-0.1em]" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
          THE DEAL TABLE
        </h1>
      </div>
    </section>
  );
};

export default DealTable;