import React from "react";
import Home from "../component/Home";

function Homepage() {
  return (
    <>
      <Home />
    </>
  );
}

export default Homepage;
