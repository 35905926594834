import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import RegistrationForm from '../component/RegistrationForm';
import InvestorsRoomForm from '../component/InvestorsRoomForm';
import ExhibitionForm from '../component/ExhibitionForm';
import programmesBgImage from "../assets/programmes-bg.jpg";

const ProgrammesPage = ({ setShowPricingModal }) => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showInvestorsRoomForm, setShowInvestorsRoomForm] = useState(false);
  const [showExhibitionForm, setShowExhibitionForm] = useState(false);

  const buttonClasses = "w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 shadow-md hover:shadow-lg";

  const sectionClasses = "mb-12 sm:mb-16 p-6 sm:p-8 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300";

  return (
    <div className="relative bg-gray-100">
      <PageHeader 
        title="PROGRAMMES" 
        description="Explore our diverse range of events designed to connect, inspire, and advance the African film industry."
        backgroundImage={programmesBgImage}
      />
      <main className="container mx-auto px-4 sm:px-6 py-12 sm:py-20">
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={`${sectionClasses} font-lexend`}
        >
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6">
            <h2 className="font-header text-3xl sm:text-4xl md:text-5xl font-bold mb-4 tracking-wider text-custom-base">FORUM SESSIONS</h2>
          </div>
          <p className="mb-4 text-sm sm:text-base leading-relaxed">
            Led by the esteemed Ministry of Arts, Culture and Creative Economy, the forum sessions cover a diverse 
            range of topics crucial to the film industry. From understanding revenue streams to navigating financing, 
            investment, risk management, production, and distribution, our renowned panelists and speakers 
            ensure enlightening and engaging discussions.
          </p>
          <p className="mb-6 text-sm sm:text-base leading-relaxed">
            Our panelists and speakers, known for their esteemed reputation and deep understanding of these 
            interconnected domains will ensure that participants engage in enlightening discussions that foster 
            knowledge exchange and innovation. Through these meticulously curated sessions, attendees gain 
            invaluable tools, strategies, and perspectives vital for success and growth in the dynamic realm of film 
            production and finance.
          </p>
          <button 
            onClick={() => setShowRegistrationForm(true)} 
            className={buttonClasses}
          >
            REGISTER TO ATTEND
          </button>
        </motion.section>

        <section className="space-y-8 sm:space-y-12">
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6">
            <h2 className="font-header text-3xl sm:text-4xl md:text-5xl font-bold mb-4 tracking-wider text-custom-base">OTHER SESSIONS</h2>
          </div>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className={`${sectionClasses} font-lexend bg-[#faf7d4]`}
          >
            <h3 className="font-header text-2xl sm:text-3xl font-bold mb-4">INVESTORS ROOM</h3>
            <p className="mb-4 p-4  rounded-lg text-sm sm:text-base leading-relaxed">
              The Investor Room is an exclusive space where accredited sales and production companies, as well as 
              film industry professionals, present their film projects to financiers for investment opportunities. Access 
              is limited to Premium Sales and Production companies, VIP Sales and Production companies, Premium 
              Elite financiers, and Premium Elite Plus financiers.
            </p>
            <p className="mb-6 p-4 rounded-lg text-sm sm:text-base leading-relaxed">
              To have a more exclusive presence at the Investors Room area, AFFF provides this room to be owned by 
              financiers or Sales and Production. Here you have a branded room for your company to enhance the 
              communication process of investment opportunities providers.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button onClick={() => setShowPricingModal(true)} className={buttonClasses}>
                GET YOUR BADGE
              </button>
              <button onClick={() => setShowInvestorsRoomForm(true)} className={buttonClasses}>
                OWN AN INVESTORS ROOM
              </button>
            </div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className={`${sectionClasses} font-lexend`}
          >
            <h3 className="font-header text-2xl sm:text-3xl font-bold mb-4 bg-[#f7f4f0]">DEAL TABLE</h3>
            <p className="mb-6 p-4  rounded-lg text-sm sm:text-base leading-relaxed">
              The Deal Table is an ultra-exclusive boardroom setting for high-budget film projects with significant ROI 
              potential. Access is reserved solely for Premium Elite Plus financiers and Premium Sales & Production 
              companies.
            </p>
            <button onClick={() => setShowPricingModal(true)} className={buttonClasses}>
              GET ON THE DEAL TABLE
            </button>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className={`${sectionClasses} font-lexend bg-[#faf7d4]`}
          >
            <h3 className="font-header text-2xl sm:text-3xl font-bold mb-4">INVESTORS LOUNGE</h3>
            <p className="mb-6 p-4 rounded-lg text-sm sm:text-base leading-relaxed">
              AFFF Investors Lounge, the ultimate hub for relaxation and rejuvenation between screenings and 
              meetings. Dive into your work, engage with industry leaders, and savor a selection of refreshing treats. 
              This exclusive retreat is reserved just for AFFF accredited financiers and Sales and Production companies.
            </p>
            <button onClick={() => setShowPricingModal(true)} className={buttonClasses}>
              GET YOUR BADGE
            </button>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className={`${sectionClasses} font-lexend`}
          >
            <h3 className="font-header text-2xl sm:text-3xl font-bold mb-4">CINETOUR EXPO</h3>
            <p className="mb-6 p-4 rounded-lg text-sm sm:text-base leading-relaxed">
              CineTour unites film commissions, government agencies, tourism sites, and production services to 
              engage with a global audience, providing exceptional opportunities for networking, collaboration, and 
              inspiration. Brands have the chance to enhance their visibility and connect with over 3,000 attendees by 
              booking a space to showcase their products over the course of three days.
            </p>
            <button onClick={() => setShowExhibitionForm(true)} className={buttonClasses}>
              EXHIBIT
            </button>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className={`${sectionClasses} font-lexend`}
          >
            <h3 className="font-header text-2xl sm:text-3xl font-bold mb-4">SPORT IN FILM PLENARY SESSION</h3>
            <p className="mb-4 p-4  rounded-lg text-sm sm:text-base leading-relaxed">
              The Sport in Films Plenary, part of the Africa Film Finance Forum, aims to harness the power of cinema 
              to drive the development of sports across Africa. By integrating sports narratives into films, the session 
              seeks to inspire and motivate audiences, especially youth, while promoting unity by bridging cultural 
              and social divides.
            </p>
            <p className="mb-6 p-4 rounded-lg text-sm sm:text-base leading-relaxed">
              It also highlights the achievements of African sports heroes, encourages youth involvement in sports, and 
              showcases the potential for films to attract investment in athletic programs and infrastructure. Ultimately, 
              the plenary is dedicated to using storytelling to foster cultural pride, social cohesion, and a vibrant sports 
              culture across the continent.
            </p>
            <button onClick={() => setShowRegistrationForm(true)} className={buttonClasses}>
              REGISTER
            </button>
          </motion.div>
        </section>
      </main>
      <Footer />

      <AnimatePresence>
        {showRegistrationForm && (
          <RegistrationForm onClose={() => setShowRegistrationForm(false)} />
        )}
        {showInvestorsRoomForm && (
          <InvestorsRoomForm onClose={() => setShowInvestorsRoomForm(false)} />
        )}
        {showExhibitionForm && (
          <ExhibitionForm onClose={() => setShowExhibitionForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProgrammesPage;