import React from 'react';
import NavigationBar from "./NavigationBar";

const PageHeader = ({ title, description, backgroundImage }) => {
  return (
    <header className="relative">
      <div className="relative w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] overflow-hidden">
        <img 
          src={backgroundImage} 
          alt={title} 
          className="w-full h-full object-cover object-[center_30%]"
        />
        {/* First overlay with blend mode */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#1a2639]/90 to-[#1a2639]/70 mix-blend-soft-light"></div>
        {/* Second overlay with fade to transparent - blue more pronounced */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#1a2639] via-[#1a2639]/70 to-transparent"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center p-4 sm:p-6 md:p-8">
          <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[4rem] tracking-wider text-white text-center text-shadow-lg max-w-4xl">
            {title}
          </h2>
          <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-lexend text-gray-200 text-center mt-2 sm:mt-4 max-w-2xl text-shadow">
            {description}
          </p>
        </div>
      </div>
      
      <div className="absolute top-0 left-0 right-0 z-10">
        <NavigationBar />
      </div>
    </header>
  );
};

export default PageHeader;