import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import RegistrationForm from '../component/RegistrationForm';
import { MapPin, Calendar, Video } from 'lucide-react';
import aboutBgImage from '../assets/pitching.webp'


// Import placeholder images (replace with actual images when available)
import affImage1 from "/sponsorship.jpg";
import affImage2 from "/attendees.jpeg";
import affImage3 from "/accomodation.jpg";

const AboutAFFFPage = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  const sectionClasses = "mb-16 bg-white rounded-lg shadow-md overflow-hidden";
  const headingClasses = "text-3xl sm:text-4xl font-bold mb-6 text-gray-800 font-lexend";
  const subheadingClasses = "text-[4rem] font-bold mb-4 text-gray-700 font-header tracking-wide";
  const paragraphClasses = "mb-4 text-base leading-relaxed text-gray-600 font-lexend";
  const buttonClasses = "bg-custom-red text-white px-6 py-3 font-bold rounded-lg hover:bg-red-600 transition duration-300 shadow-md hover:shadow-lg font-lexend";

  return (
    <div className="bg-[#fcf7f3] min-h-screen font-lexend">
      <PageHeader 
        title="Africa Film Finance Forum 2024" 
        description="A home for film projects with guaranteed ROI"
        backgroundImage={aboutBgImage}
      />
      <main className="container mx-auto px-4 sm:px-6 py-12">
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={sectionClasses}
        >
          <div className="flex flex-col md:flex-row p-8">
            <div className="md:w-1/2 pr-0 md:pr-8">
              <h2 className={subheadingClasses}>Welcome to AFFF 2024</h2>
              <p className={paragraphClasses}>
                The Africa Film Finance Forum (AFFF) is a specialized platform catering to financiers and film
                professionals. Join us for an unparalleled opportunity to delve deep into the film industry,
                covering market trends, emerging opportunities, and the intricacies of film financing.
              </p>
              <button 
                onClick={() => setShowRegistrationForm(true)} 
                className={`${buttonClasses} mt-4`}
              >
                REGISTER NOW
              </button>
            </div>
            <div className="md:w-1/2 mt-6 md:mt-0 grid grid-cols-2 gap-2">
              <img src={affImage1} alt="AFFF Event" className="w-full h-40 object-cover rounded" />
              <img src={affImage2} alt="AFFF Networking" className="w-full h-40 object-cover rounded" />
              <img src={affImage3} alt="AFFF Presentation" className="w-full col-span-2 h-40 object-cover rounded" />
            </div>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg">
            <div className="flex items-center mb-4">
              <MapPin className="text-gray-600 mr-2" />
              <span className="text-gray-700 font-semibold">Lagos Oriental Hotel, Lagos, Nigeria</span>
            </div>
            <div className="flex items-center mb-4">
              <Calendar className="text-gray-600 mr-2" />
              <span className="text-gray-700 font-semibold">November 26th - 28th, 2024</span>
            </div>
            <div className="flex items-center">
              <Video className="text-gray-600 mr-2" />
              <span className="text-gray-700 font-semibold">In Person & Virtual Events</span>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={sectionClasses}
        >
          <div className="p-8">
            <h2 className={subheadingClasses}>Key Dates</h2>
            <div className="space-y-4">
              {[
                { title: "Exhibitors Registration", date: "JULY 1ST, 2024" },
                { title: "Film Screening Registration", date: "JULY 3RD, 2024" },
                { title: "Pitch Competition Starts", date: "AUGUST 15TH, 2024" },
                { title: "Film Screening Registration Deadline", date: "SEPTEMBER 21ST, 2024" },
                { title: "Pitch Competition Ends", date: "OCTOBER 5TH, 2024" },
              ].map((item, index) => (
                <div key={index} className="flex justify-between items-center border-b pb-2">
                  <span className="font-semibold text-gray-700">{item.title}</span>
                  <span className="text-custom-red font-roboto text-[1.2rem] border  border-gray-600 font-bold bg-gray-100 w-[300px] rounded p-3 shadow-md">{item.date}</span>
                </div>
              ))}
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className={sectionClasses}
        >
          <div className="p-8">
            <h2 className={subheadingClasses}>AFFF Highlights</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
              {[
                "Sales & Production",
                "Screening & Pitch Sessions",
                "Market Review Session",
                "Investors Lounge",
                "Deal Table",
                "CineTour Expo",
              ].map((item, index) => (
                <div key={index} className="border rounded-lg p-4 hover:shadow-md transition-shadow duration-300">
                  <h3 className="font-bold text-lg mb-2 text-gray-800 font-roboto">{item}</h3>
                  <Link to={`/${item.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-')}`} className="text-custom-red font-semibold hover:underline">
                    Learn more <ChevronRight size={16} className="inline" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-center mt-12"
        >
          <button 
            onClick={() => setShowRegistrationForm(true)} 
            className={`${buttonClasses} text-lg px-8 py-4`}
          >
            REGISTER TO ATTEND
          </button>
        </motion.section>
      </main>
      <Footer />

      <AnimatePresence>
        {showRegistrationForm && (
          <RegistrationForm onClose={() => setShowRegistrationForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AboutAFFFPage;