import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import pages
import Homepage from "./pages/Homepage";
import Contactpage from "./pages/Contactpage";
import AttendPage from "./pages/AttendPage";
import ProgrammesPage from "./pages/ProgrammesPage";
import PitchSessionsPage from "./pages/PitchSessionsPage";
import MarketReviewSessionPage from "./pages/MarketReviewSessionPage";
import MasterClassPage from "./pages/MasterClassPage";
import ForumSpeakersPage from "./pages/ForumSpeakersPage";
import SpecialEventPage from "./pages/SpecialEventPage";
import FilmScreeningPage from "./pages/FilmScreeningPage";
import FilmPremierPage from "./pages/FilmPremierPage";
import AccreditedFilmProductionCompaniesPage from "./pages/AccreditedFilmProductionCompaniesPage";
import HowToExhibitPage from "./pages/HowToExhibitPage";
import WhoCanExhibitPage from "./pages/WhoCanExhibitPage";
import DealTablePage from "./pages/DealTablePage";
import InvestorsRoomPage from "./pages/InvestorsRoomPage";
import CineTourExpoPage from "./pages/CineTourExpoPage";
import SponsorshipOpportunitiesPage from "./pages/SponsorshipOpportunitiesPage";
import BrandsSponsorshipPackagesPage from "./pages/BrandsSponsorshipPackagesPage";
import OpportunitiesBenefitsPage from "./pages/OpportunitiesBenefitsPage";
import FinancierAccreditationPage from "./pages/FinancierAccreditationPage";
import AttendeesPage from "./pages/AttendeesPage";
import WhoShouldAttendPage from "./pages/WhoShouldAttendPage";
import AccommodationPage from "./pages/AccommodationPage";
import AboutPage from "./pages/AboutAFFFPage";
import AdvisorsPage from "./pages/AFFFAdvisorsPage";
import VenuePage from "./pages/VenuePage";
import FAQPage from "./pages/FAQPage";
import PaymentVerification from './component/PaymentVerification';
  

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<Contactpage />} />
        <Route path="/attend" element={<AttendPage />} />
        
        {/* Programmes Routes */}
        <Route path="/programmes" element={<ProgrammesPage />} />
        <Route path="/programmes/pitch-sessions" element={<PitchSessionsPage />} />
        <Route path="/programmes/market-review" element={<MarketReviewSessionPage />} />
        <Route path="/programmes/master-class" element={<MasterClassPage />} />
        <Route path="/programmes/forum-speakers" element={<ForumSpeakersPage />} />
        <Route path="/programmes/special-event" element={<SpecialEventPage />} />
        <Route path="/programmes/film-screening" element={<FilmScreeningPage />} />
        <Route path="/programmes/film-premier" element={<FilmPremierPage />} />

        {/* Exhibit Routes */}
        <Route path="/exhibit/accredited-companies" element={<AccreditedFilmProductionCompaniesPage />} />
        <Route path="/exhibit/how-to-exhibit" element={<HowToExhibitPage />} />
        <Route path="/exhibit/who-can-exhibit" element={<WhoCanExhibitPage />} />
        <Route path="/exhibit/deal-table" element={<DealTablePage />} />
        <Route path="/exhibit/investors-room" element={<InvestorsRoomPage />} />
        <Route path="/exhibit/cinetour-expo" element={<CineTourExpoPage />} />

        {/* About Routes */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about/advisors" element={<AdvisorsPage />} />
        <Route path="/about/venue" element={<VenuePage />} />
        <Route path="/about/faq" element={<FAQPage />} />
        

        {/* Sponsor Routes */}
        <Route path="/sponsor/opportunities" element={<SponsorshipOpportunitiesPage />} />
        <Route path="/sponsor/brands-packages" element={<BrandsSponsorshipPackagesPage />} />
        <Route path="/sponsor/benefits" element={<OpportunitiesBenefitsPage />} />

        {/* Attend Routes */}
        <Route path="/financier-accreditation" element={<FinancierAccreditationPage />} />
        <Route path="/attendees" element={<AttendeesPage />} />
        <Route path="/who-should-attend" element={<WhoShouldAttendPage />} />
        <Route path="/accommodation" element={<AccommodationPage />} />

        {/* Payment Routes */}
        <Route path="/payment/verify" element={<PaymentVerification />} />



        {/* Catch-all route for 404 - Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

// Simple NotFound component
const NotFound = () => (
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you're looking for doesn't exist.</p>
  </div>
);

export default App;