import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PricingModal from "../component/PricingModal";
import investorsRoomBgImage from "/investors-room.jpeg";
import { Icon } from '@iconify/react';

const InvestorsRoomPage = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  return (
    <div className="relative min-h-screen flex flex-col">
      <PageHeader 
        title="AFFF INVESTORS ROOM" 
        description="A premium space for accredited companies to present projects to high-level financiers."
        backgroundImage={investorsRoomBgImage}
      />
      <main className="flex-grow bg-white p-4 sm:p-6 flex items-center justify-center">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <section className="font-lexend text-center">
            <p className="mb-8 text-lg sm:text-xl leading-relaxed">
              The Investor Room is a premium space where accredited sales and production companies, along with
              other film industry professionals, present their projects to high-level financiers. Access is restricted to
              Premium and VIP Sales & Production companies, as well as Premium Elite and Premium Elite Plus
              financiers. This exclusive setting facilitates focused presentations and networking, aiming to secure
              significant investments and foster strategic partnerships.
            </p>
            <div className="flex justify-center mb-8">
              <Icon icon="mdi:presentation" className="text-8xl text-custom-red mr-4" />
              <Icon icon="mdi:cash-multiple" className="text-8xl text-custom-red" />
            </div>
            <button 
              onClick={() => setIsPricingModalOpen(true)}
              className="bg-custom-red text-white px-8 py-4 text-center font-bold font-roboto text-lg rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 shadow-lg hover:shadow-xl"
            >
              GET YOUR BADGE NOW
            </button>
          </section>
        </div>
      </main>
      <Footer />
      <PricingModal isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
    </div>
  );
};

export default InvestorsRoomPage;