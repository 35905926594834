import React, { useState } from 'react';
import BaseForm from './BaseForm';
import PaymentStep from './PaymentStep';
import { initiatePayment } from '../services/api';

const ExhibitionForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    contactName: '',
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: '',
  });
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true);
    setError('');
    try {
      const response = await initiatePayment(formData.email, 140, 'USD', 'exhibition', formData);
      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        throw new Error('No redirect URL received');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setError('There was an error initiating the payment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="AFFF Exhibition/Showcase" onClose={onClose}>
      {step === 1 ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          {Object.keys(formData).map((key) => (
            <div key={key}>
              <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
                {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              </label>
              <input
                type={key === 'email' ? 'email' : 'text'}
                name={key}
                id={key}
                value={formData[key]}
                onChange={handleChange}
                className="form-input w-full"
                required
              />
            </div>
          ))}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              NEXT
            </button>
          </div>
        </form>
      ) : (
        <PaymentStep
          amount={140}
          onBack={() => setStep(1)}
          onSubmit={handlePaymentSubmit}
          isSubmitting={isSubmitting}
          error={error}
        />
      )}
    </BaseForm>
  );
};

export default ExhibitionForm;