import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import { Icon } from '@iconify/react';
import { motion, AnimatePresence } from 'framer-motion';
import opportunitiesBgImage from '/who-can.jpg';

const OpportunitiesBenefitsPage = () => {
  const [openBenefit, setOpenBenefit] = useState(null);

  const benefits = [
    {
      title: "High-Profile Branding",
      description: "AFFF offers unparalleled visibility through strategic placement of your brand's logo on event materials, digital platforms, and signage, aligning your brand with a premier industry event and enhancing recognition among industry leaders and potential clients.",
      icon: "mdi:trophy-outline"
    },
    {
      title: "Networking Opportunities",
      description: "Connect directly with top-tier professionals in the film and finance industries, including filmmakers, producers, investors, and financial experts, at exclusive networking sessions and tailored business matchmaking events, fostering valuable partnerships and business deals.",
      icon: "mdi:account-group-outline"
    },
    {
      title: "Market Penetration",
      description: "Leverage AFFF's platform to tap into the lucrative $20 billion film industry, positioning your brand as a key supporter of film production and financing, and gaining access to a diverse audience of industry insiders and decision-makers.",
      icon: "mdi:chart-line"
    },
    {
      title: "Thought Leadership",
      description: "Showcase your brand's expertise and commitment to industry growth by participating in thought-provoking panels, workshops, and discussions hosted by AFFF, sharing insights, innovative ideas, and best practices to establish your brand as a thought leader and industry influencer.",
      icon: "mdi:lightbulb-on-outline"
    },
    {
      title: "Exclusive Access",
      description: "Enjoy VIP access to premier screenings, exclusive events, and intimate meetings with influential decision-makers during the AFFF event, providing unique opportunities to forge meaningful relationships, secure partnerships, and stay ahead of industry trends.",
      icon: "mdi:vip"
    },
    {
      title: "Corporate Social Responsibility",
      description: "Demonstrate your brand's dedication to social impact and community development by supporting AFFF, an event that promotes industry growth, cultural enrichment, and economic development, enhancing your brand's reputation and resonating with socially conscious consumers.",
      icon: "mdi:hand-heart"
    },
    {
      title: "Promotional Benefits",
      description: "Maximize your brand's exposure through extensive coverage in AFFF magazines, newsletters, and promotional materials distributed during and after the event, ensuring ongoing visibility, recognition, and engagement with industry stakeholders.",
      icon: "mdi:megaphone"
    },
    {
      title: "Targeted Audience Reach",
      description: "At AFFF, gain access to a highly targeted audience comprising industry professionals, influencers, and decision-makers. This unique opportunity allows you to showcase your products, services, and investment opportunities to a relevant and engaged audience actively seeking innovative solutions and partnerships.",
      icon: "mdi:target-account"
    },
  ];

  const advertSlots = [
    {
      title: "AFFF Video Advertisement",
      description: "Display your film trailers or company promotional video on AFFF screens positioned in the three days event hall, reaching an audience of more than 3,000 attendees.",
      prices: [
        { duration: "3 minutes", price: "$400" },
        { duration: "5 minutes", price: "$800" }
      ]
    },
    {
      title: "AFFF Magazine Advert Slot",
      description: "Advertise in AFFF magazine to ensure your brand is seen by a vast audience of industry leaders, professionals, and influencers. With extensive distribution before, during, and after the event, your advertisement will gain unparalleled visibility and lasting impact, driving brand recognition and business opportunities long after the event concludes.",
      prices: [
        { type: "Full page", price: "$300" },
        { type: "Half page", price: "$170" }
      ]
    }
  ];

  const BenefitAccordion = ({ benefit, index }) => {
    const isOpen = openBenefit === index;

    return (
      <div className="mb-4 font-lexend">
        <button
          className="flex justify-between items-center w-full p-4 bg-[#6e6a33] text-white rounded-lg focus:outline-none"
          onClick={() => setOpenBenefit(isOpen ? null : index)}
        >
          <div className="flex items-center">
            <Icon icon={benefit.icon} className="text-2xl mr-3" />
            <span className="font-roboto text-2xl font-bold">{benefit.title}</span>
          </div>
          <Icon icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"} className="text-xl" />
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white p-4 rounded-b-lg shadow-inner"
            >
              <p className="text-normal text-gray-900">{benefit.description}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const AdvertSlot = ({ slot }) => (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
      <div className="bg-[#00323D] text-white p-6">
        <h3 className="font-roboto text-2xl font-bold mb-2">{slot.title}</h3>
        <p className="text-normal opacity-90">{slot.description}</p>
      </div>
      <div className="p-6">
        {slot.prices.map((price, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <span className="text-sm font-semibold">{price.duration || price.type}</span>
            <span className="text-lg font-bold text-[#00323D]">{price.price}</span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="relative bg-gray-100 ">
      <PageHeader 
        title="OPPORTUNITIES AND BENEFITS" 
        description="Discover the advantages of partnering with AFFF"
        backgroundImage={opportunitiesBgImage}
      />
      <main className="py-12 px-4 sm:px-6 lg:px-8 font-lexend mb-36">
        <div className="max-w-4xl mx-auto">
          <section className="mb-16">
            <h2 className="font-header text-4xl sm:text-5xl font-bold mb-8 text-center text-[#00323D]">Sponsorship Benefits</h2>
            <div className="bg-white rounded-lg shadow-lg p-6">
              {benefits.map((benefit, index) => (
                <BenefitAccordion key={index} benefit={benefit} index={index} />
              ))}
            </div>
          </section>

          <section>
            <h2 className="font-header text-4xl sm:text-5xl font-bold mb-8 text-center text-[#00323D]">Advert Slots</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {advertSlots.map((slot, index) => (
                <AdvertSlot key={index} slot={slot} />
              ))}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default OpportunitiesBenefitsPage;