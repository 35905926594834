import React from 'react';

const PaymentStep = ({ amount, onBack, onSubmit, isSubmitting, error }) => {
  return (
    <div className="bg-gray-100 p-6 border-gray-800 border-2 rounded-lg shadow-lg space-y-6 max-w-md mx-auto">
      <h3 className="text-2xl font-roboto font-semibold text-gray-800">Payment Information</h3>
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
        <p className="text-lg text-gray-800">Total Amount:</p>
        <p className="text-3xl font-black text-red-600 mt-1">${amount} USD</p>
      </div>
      <p className="mb-4">You will be redirected to Paystack to complete your payment securely.</p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="flex justify-between">
        <button
          onClick={onBack}
          className="bg-gray-300 text-black px-4 py-2 rounded-tl-lg rounded-br-lg hover:bg-gray-400 transition duration-300"
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          onClick={onSubmit}
          className={`px-4 py-2 rounded-tl-lg rounded-br-lg transition duration-300 ${
            isSubmitting
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-custom-red text-white hover:bg-red-600'
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Securely transferring to Paystack...' : 'Proceed to Payment'}
        </button>
      </div>
    </div>
  );
};

export default PaymentStep;