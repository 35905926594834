import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import RegistrationForm from '../component/RegistrationForm';
import SessionsCard from '../component/SessionsCard';
import attendeesBgImage from "/attendees.jpeg";

const AttendeesPage = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  const accordionContent = [
    "In our sector, your success greatly relies on your ingenuity, industry awareness, and grasp of current trends. Over the course of three days, AFFF will immerse you in the global film community, offering vital chances to network, enhance visibility for your projects, explore fresh production possibilities, develop upcoming ventures, and engage with industry professionals who finance, produce, and distribute films worldwide.",
    "The African film industry has seen notable changes, especially in recent years. AFFF Sessions will feature more than 58 distinguished speakers, sharing insights on independent film, financing, production, distribution, and refining your pitch.",
    "Whether you're seeking funding, representation, creative partnerships, production resources, or are ready to pitch your next project, the AFFF provides an ideal backdrop to forge meaningful connections and advance your goals.",
    "Our dedicated team will be available to help you both before and during AFFF, ensuring that all your inquiries are addressed. Reach out to us via email at project@africafilmfinanceforum.com"
  ];

  return (
    <div className="relative font-lexend">
      <PageHeader 
        title="INDUSTRY PROFESSIONALS" 
        description="Producers, Directors, Scriptwriters, Actors, film Crew, Film distributors, Film studios, Film exhibitors, Cinematographers"
        backgroundImage={attendeesBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
          <section className="mb-12 sm:mb-16 text-center">
            <button 
              onClick={() => setShowRegistrationForm(true)}
              className="bg-custom-red text-white px-8 py-4 rounded-lg font-bold text-lg hover:bg-red-600 transition duration-300 shadow-lg"
            >
              REGISTER TO ATTEND
            </button>
          </section>

          <section className="mb-12 sm:mb-16">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">AFFF SESSIONS</h2>
            </div>
            
            <SessionsCard />
          </section>

          <section className="w-full bg-[#FAF0E6] p-6 sm:p-8 rounded-lg mb-12 sm:mb-16">
            <h2 className="font-header text-4xl sm:text-3xl lg:text-6xl font-bold mb-6 tracking-wider text-custom-base">AFFF is where finance meet ideas to create blockbusters.</h2>
            <h3 className="font-roboto text-xl sm:text-2xl font-bold mb-6">WHY ATTEND AFFF?</h3>
            
            {accordionContent.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-6">
                <p className="text-sm sm:text-base">{item}</p>
              </div>
            ))}
          </section>

          <section className="text-center mt-12">
            <p className="font-bold text-xl mb-6">DON'T MISS YOUR MOMENT</p>
            <button 
              onClick={() => setShowRegistrationForm(true)}
              className="bg-custom-red text-white px-8 py-4 rounded-lg font-bold text-lg hover:bg-red-600 transition duration-300 shadow-lg"
            >
              REGISTER TO ATTEND
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showRegistrationForm && (
          <RegistrationForm onClose={() => setShowRegistrationForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AttendeesPage;