import React from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import accommodationBgImage from "/accomodation.jpg";

const AccommodationPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="ACCOMMODATION" 
        description="We've careafully selected various categories of comfortable, secured lodging for your AFFF 2024 experience!"
        backgroundImage={accommodationBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">Accommodation Options</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              Subject to availability, AFFF provides guaranteed rates for guest rooms for registered participants. We have partnered with several hotels in the vicinity to ensure comfortable and convenient lodging options for all attendees.
            </p>
          </section>

          {/* <section className="mb-8 sm:mb-12">
            <h3 className="font-header text-xl sm:text-2xl font-bold mb-4">Partner Hotels</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

              <div className="div-with-bold-shadow p-4">
                <h4 className="font-bold mb-2">Hotel A</h4>
                <p>Description of Hotel A and its amenities...</p>
                <p className="mt-2 font-bold">Special AFFF Rate: $X per night</p>
              </div>
              <div className="div-with-bold-shadow p-4">
                <h4 className="font-bold mb-2">Hotel B</h4>
                <p>Description of Hotel B and its amenities...</p>
                <p className="mt-2 font-bold">Special AFFF Rate: $Y per night</p>
              </div>

            </div>
          </section> */}

          <section className="mb-8 sm:mb-12 font-lexend">
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">Booking Instructions</h2>
          </div>
            <p className="mb-4 text-sm sm:text-base">
              To take advantage of our special rates, please follow these steps:
            </p>
            <ol className="list-decimal pl-5 text-sm sm:text-base">
              <li>Register for AFFF</li>
              <li>Receive your confirmation email with a unique booking code</li>
              <li>Contact our partner hotels directly and provide your booking code</li>
              <li>Complete your reservation at the guaranteed AFFF rate</li>
            </ol>
          </section>

          <section className="text-center mt-8">
            <p className="font-bold text-lg mb-4">For any accommodation-related inquiries, please contact us at:</p>
            <p className="text-custom-red font-bold">accommodation@africafilmfinanceforum.com</p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccommodationPage;