import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import SessionsCard from '../component/SessionsCard';
import exhibitBgImage from "/accredited-companies.jpg";

const AccreditedFilmProductionCompaniesPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="SALES AND PRODUCTION" 
        description="Engage with over 3,000 global participants at AFFF, offering unparalleled networking opportunities and insights."
        backgroundImage={exhibitBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">Accredited Financiers</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              An AFFF financier is a company or individual committed to investing in all facets of film production and
              release, from marketing to distribution. At the three-day event, these accredited financiers will be on-
              site, actively seeking investment opportunities and exploring business partnerships with the
              participating companies.
            </p>
          </section>

          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">Event Highlights</h2>
            </div>
            
            <SessionsCard />
          </section>

          <section className="w-full bg-[#FAF0E6] p-6 sm:p-8 rounded-lg mb-12 sm:mb-16 text-lexend">
          <h2 className="font-header text-4xl sm:text-3xl lg:text-6xl font-bold mb-6 tracking-wider text-custom-base">WHY YOU SHOULD EXHIBIT AT AFFF</h2>
            <p className="mb-4 text-sm sm:text-base">
              Exhibiting at AFFF positions you at the center of the film industry's most influential event. This is your
              chance to connect with a global audience of decision-makers and investors who are actively seeking new
              opportunities. By showcasing your work, you can attract crucial funding, establish valuable partnerships,
              and elevate your profile among key industry players. This is a high-impact platform that offers
              unparalleled access to the people who can drive your success in the film industry.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              To discuss your exhibit options contact us at <b>project@africafilmfinanceforum.com</b>
            </p>
            <button 
              onClick={() => setShowContactForm(true)} 
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
            >
              CONTACT US
            </button>
          </section>

          <div className="text-center mt-8">
            <p className="font-bold font-header tracking-wider text-[3rem] mb-4">EARLY REGISTRATION IS IMPORTANT TO SECURE A SPACE</p>
            <Link to="/exhibit/how-to-exhibit" className="w-full sm:w-auto bg-custom-red font-roboto font-bold text-white px-4 sm:px-6 py-2 sm:py-3 text-center text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 inline-block">HOW TO EXHIBIT</Link>
          </div>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccreditedFilmProductionCompaniesPage;