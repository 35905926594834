import React from "react";
import male from "../assets/male-speaker.png";
import female from "../assets/female-speaker-min.png";
import chief from "../assets/chief-min.png";

const Speakers = () => {
  return (
    <section className="lg:pt-[5rem] pt-[2rem] lg:pb-[10rem] pb-[2rem] px-[4%]">
      <div>
        <h4 className="font-header capitalize tracking-wider font-semibold text-center lg:pt-[4.43rem] pt-[2rem] lg:text-[3.5rem] text-[2.5rem]">
          KEYNOTE SPEAKERS AND CO-CHAIRS
        </h4>
        <div className="flex lg:flex-row flex-col lg:mt-12 mt-8 lg:items-start items-center justify-center gap-[3.5rem]">
          {/* Female speaker */}
          <div className="flex flex-col justify-center items-center">
            <img
              className="lg:w-[24.75rem] lg:h-[30.49rem] w-[22rem] h-[26rem]"
              src={female}
              alt="female-speaker"
            />
            <div
              className="bg-[#574C75] lg:mt-8 mt-4 w-[27rem] h-[6rem] flex items-center justify-center"
              style={{
                clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
              }}
            >
              <div
                className="bg-white w-[26.9rem] pt-[0.5rem] pb-[0.31rem] px-[2.5rem] h-[5.9rem]"
                style={{
                  clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                }}
              >
                <div className="lg:text-left text-center">
                  <p className="font-roboto lg:text-[1.5rem] text-[1.3rem]">
                    Hon.Min Hanatu Musa Musawa
                  </p>
                  <p className="font-lato font-normal -mb-[0.2rem]">
                    Minister Arts, Culture & Creative Economy
                  </p>
                  <p className="font-lato font-normal">
                    Federal Republic of Nigeria
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Male Speaker */}
          <div className="flex flex-col justify-center items-center">
            <img
              className="lg:w-[24.75rem] lg:h-[30.49rem] w-[22rem] h-[26rem]"
              src={male}
              alt="male-speaker"
            />
            <div
              className="bg-[#574C75] lg:mt-8 mt-4 w-[27rem] h-[6rem] flex items-center justify-center"
              style={{
                clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
              }}
            >
              <div
                className="bg-white w-[26.9rem] pt-[0.5rem] pb-[0.31rem] px-[2.5rem] h-[5.9rem]"
                style={{
                  clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                }}
              >
                <div className="lg:text-left text-center">
                  <p className="font-roboto lg:text-[1.5rem] text-[1.3rem]">
                    Hon.Min Olawale Edun
                  </p>
                  <p className="font-lato font-normal -mb-[0.2rem]">
                    Minister of Finance{" "}
                  </p>
                  <p className="font-lato font-normal">
                    Federal Republic of Nigeria
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Chief Host */}
        <div className="flex flex-col justify-center items-center mt-[6rem]">
              <h2 className="font-header capitalize font-semibold tracking-wider text-center lg:text-[3.2rem] leading-8 text-[2.5rem] mb-6">
                CHIEF HOST, <br/>
                <span className="font-roboto font-normal text-[1.5rem]">SPORTS IN FILM PLENARY </span>
              </h2>
            </div>
        <div className="flex justify-center">
          <div className="flex flex-col lg:flex-row lg:items-start items-center justify-center lg:gap-0 gap-[1rem]">
            <div className="flex flex-col justify-center items-center">
              <img
                className="lg:w-[23rem] lg:h-[32rem] w-[22rem] h-[24rem] rounded-md"
                src={chief}
                alt="chief-png"
              />
              <div
                className="bg-[#574C75] lg:mt-8 mt-4 w-[27rem] h-[6rem] flex items-center justify-center"
                style={{
                  clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                  boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
                }}
              >
                <div
                  className="bg-white w-[26.9rem] pt-[0.5rem] pb-[0.31rem] px-[2.5rem] h-[5.9rem]"
                  style={{
                    clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
                  }}
                >
                  <div className="lg:text-left text-center">
                    <p className=" lg:text-[1.5rem] text-[1.3rem] font-roboto">
                      Hon.Min John Enoh
                    </p>
                    <p className="font-lato font-normal -mb-[0.2rem]">
                      Minister of Sports Development
                    </p>
                    <p className="font-lato font-normal">
                      Federal Republic of Nigeria
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-[#f5f4e5] lg:text-left text-center lg:w-[36rem] px-[1.8rem] py-[2.93rem] font-rand rounded-[4px]"
              style={{ boxShadow: "1px 4px 8px 2px rgba(0, 0, 0, 0.12)" }}
            >
              <p className="font-bold font-lexend lg:text-[1.7rem] text-[1.2rem] lg:leading-[2.3rem] leading-[1.5rem]">
                The Sport in Film Plenary: Driving the Development of Sports
                through Cinema
              </p>
              <p className="lg:text-[1.2rem] font-lexend mt-4 lg:leading-[2rem] leading-[1.5rem]">
                The Sport in Films Plenary is a dedicated session within the
                Africa Film Finance Forum, is designed to foster discussions on
                integrating sports narratives into African films. This plenary
                highlights the importance of incorporating sports themes into
                film content, showcasing the potential to celebrate African
                sports heroes and iconic moments. It aims to promote unity,
                inspire resilience in younger generations, and foster cultural
                pride.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Speakers;