import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentVerification = () => {
  const [status, setStatus] = useState('Verifying payment...');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get('reference');

      if (reference) {
        try {
          console.log('API URL:', import.meta.env.VITE_API_URL);
          console.log('Verifying reference:', reference);

          const response = await axios.get(`https://africafilmfinanceforum.com/api/payments/verify/${reference}`);
          
          console.log('Verification response:', response.data);

          if (response.data.status === 'success') {
            setStatus('Payment successful! Redirecting...');
            setTimeout(() => {
              navigate('/payment-success');
            }, 2000);
          } else {
            setError(`Payment verification failed. ${response.data.message}`);
          }
        } catch (error) {
          console.errorconsole.error('Error verifying payment:', error);
          if (error.response) {
            setError(`Payment verification failed: ${error.response.data.message}. Details: ${JSON.stringify(error.response.data.details)}`);
          } else if (error.request) {
            setError('Payment verification failed. No response received from server.');
          } else {
            setError(`An error occurred during payment verification: ${error.message}`);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('Invalid payment reference.');
        setIsLoading(false);
      }
    };

    verifyPayment();
  }, [location, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-center">Payment Verification</h2>
        {isLoading ? (
          <div className="text-center">
            <p className="text-lg mb-4">{status}</p>
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          </div>
        ) : error ? (
          <div className="text-center">
            <p className="text-lg text-red-600 mb-4">{error}</p>
            <button
              onClick={() => navigate('/pricing')}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Return to Pricing
            </button>
          </div>
        ) : (
          <p className="text-lg text-center text-green-600">{status}</p>
        )}
      </div>
    </div>
  );
};

export default PaymentVerification;