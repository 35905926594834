import React from 'react';
import { Link } from 'react-router-dom';
import { Users, BriefcaseIcon, MapPin, Video, Film, BookOpen } from 'lucide-react';

const SESSIONS = [
  {
    title: "African Film Finance Forum Sessions",
    description: "Led by the esteemed Ministry of Arts and Creative Economy, covering diverse topics crucial to the film industry.",
    link: "/programmes",
    icon: Film,
    bgColor: "bg-blue-100"
  },
  {
    title: "Masterclass",
    description: "Exclusive tutoring from seasoned professionals in the film and finance industries.",
    link: "/programmes/master-class",
    icon: BookOpen,
    bgColor: "bg-green-100"
  },
  {
    title: "Screening & Pitch Sessions",
    description: "Dynamic blend of screening sessions featuring released and unreleased films. Selected professionals can pitch new projects directly to potential financiers.",
    link: "/programmes/film-screening",
    icon: Video,
    bgColor: "bg-yellow-100"
  },
  {
    title: "Networking",
    description: "Connect with top experts from within and outside Africa's movie industry, as well as potential investors.",
    link: "/programmes/special-event",
    icon: Users,
    bgColor: "bg-purple-100"
  },
  {
    title: "Investors Room",
    description: "Exclusive space for accredited companies to present projects. Access limited to premium and VIP attendees.",
    link: "/exhibit/investors-room",
    icon: BriefcaseIcon,
    bgColor: "bg-red-100"
  },
  {
    title: "CineTour Expo",
    description: "Brings together film commissions, agencies, tourism sites, and production services for networking and collaboration.",
    link: "/exhibit/cinetour-expo",
    icon: MapPin,
    bgColor: "bg-indigo-100"
  }
];

const SessionsCard = () => {
  return (
    <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
      {SESSIONS.map((session, index) => {
        const IconComponent = session.icon;
        return (
          <div key={index} className={`${session.bgColor} rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300`}>
            <div className="flex items-center mb-4">
              <IconComponent className="w-8 h-8 mr-3" />
              <h3 className="font-roboto text-xl sm:text-2xl font-bold">{session.title}</h3>
            </div>
            <p className="mb-4 text-sm sm:text-base">{session.description}</p>
            <Link to={session.link} className="text-custom-red font-bold hover:underline">LEARN MORE</Link>
          </div>
        );
      })}
    </div>
  );
};

export default SessionsCard;