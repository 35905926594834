import React from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import specialEventBgImage from "../assets/special-event-bg.jpg"; // Make sure this image exists

const SpecialEventPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="SPECIAL EVENT" 
        description="Unique experiences and networking opportunities at AFFF."
        backgroundImage={specialEventBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">SPECIAL EVENT</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              Details about our special events will be updated progressively. Check back soon for exciting
              announcements about unique experiences and networking opportunities at AFFF.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Our special events are designed to provide unforgettable experiences that combine entertainment, networking, and cultural exploration. These events may include:
            </p>
            <ul className="list-disc pl-5 mb-4 text-sm sm:text-base">
              <li>Exclusive film screenings</li>
              <li>Gala dinners with industry leaders</li>
              <li>Cultural showcases celebrating African art and music</li>
              <li>Themed networking events</li>
              <li>Interactive workshops and demonstrations</li>
            </ul>
            <p className="mb-4 text-sm sm:text-base">
              Stay tuned for more information on these exciting events that will enhance your AFFF experience!
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SpecialEventPage;