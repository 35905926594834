import React, { useState } from 'react';
import BaseForm from './BaseForm'; // Assuming you have a BaseForm component for the modal layout
import axios from 'axios';

const FinancierAccreditationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    financierName: '', // Ensure this is included
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    console.log(formData);  // Log the form data to ensure financierName is being captured

    try {
      const response = await axios.post(`https://africafilmfinanceforum.com/api/financier-accreditation`, formData);
      setSuccess('Thank you for applying for Financier Accreditation. We will contact you with further details soon.');
      setTimeout(() => onClose(), 3000); // Close the form after 3 seconds
    } catch (error) {
      setError('Accreditation submission failed. Please try again later.');
      console.error('Financier accreditation error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="Financier Accreditation Form" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Company Name
          </label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="financierName" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Financier Name
          </label>
          <input
            type="text"
            name="financierName"
            id="financierName"
            value={formData.financierName}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="contactDesignation" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Contact Designation
          </label>
          <input
            type="text"
            name="contactDesignation"
            id="contactDesignation"
            value={formData.contactDesignation}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="companyAddress" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Company Address
          </label>
          <input
            type="text"
            name="companyAddress"
            id="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            City
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            State
          </label>
          <input
            type="text"
            name="state"
            id="state"
            value={formData.state}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Country
          </label>
          <input
            type="text"
            name="country"
            id="country"
            value={formData.country}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            ZIP/Post Code
          </label>
          <input
            type="text"
            name="zipCode"
            id="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1 uppercase">
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-input w-full"
            required
          />
        </div>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}

        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            CANCEL
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'SUBMITTING...' : 'SUBMIT'}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default FinancierAccreditationForm;
