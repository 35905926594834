import { useState } from "react";
import Dropup from "../assets/dropup.png";
import Dropdown from "../assets/dropdown.png";
function Accordion({ title, content, topic }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      onClick={toggleAccordion}
      className=" w-[100%] border mb-4 rounded-lg overflow-hidden cursor-pointer custom-large:w-[90%]"
    >
      <div className="pl-5 flex items-center justify-between bg-gray-100">
        <div className="flex items-center gap-6">
          <h6 className="text-[14px] font-body text-gray-500 sm:text-[16px]">
            {title}
          </h6>
          <h2 className="text-[12px] font-body border-l-2 border-gray-300 pl-4 sm:text-[20px]">
            {topic}
          </h2>
        </div>
        <button>
          {isOpen ? (
            <img style={{ width: "50px" }} src={Dropup} alt="up" />
          ) : (
            <img style={{ width: "50px" }} src={Dropdown} alt="down" />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          style={{ borderBottom: "3px solid rgba(131, 122, 47, 1)" }}
          className="px-10 py-4"
        >
          <ul className="list-disc font-body">
            {content.map((item, index) => (
              <li key={index} className="text-[15px] mt-6">
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Accordion;
