import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PitchSubmissionForm from '../component/PitchSubmissionForm';
import pitchSessionsBgImage from "../assets/pitch-sessions-bg.jpg"; // Make sure this image exists

const PitchSessionsPage = () => {
  const [showPitchForm, setShowPitchForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="PITCH SESSIONS" 
        description="Showcase your innovative film projects to potential financiers on our prestigious stage."
        backgroundImage={pitchSessionsBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">PITCH SESSIONS</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              On the prestigious AFFF stage, selected film industry professionals will have the exclusive opportunity to
              pitch their innovative new projects directly to potential financiers.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              The process involves submitting project synopses to the AFFF commission, which comprises seasoned
              experts in the film and finance industries. Following a thorough review, a select few will earn the
              opportunity to pitch their projects on the prestigious AFFF main stage.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              This direct interaction between filmmakers and financiers fosters meaningful connections, sparks
              collaborations, and opens doors for funding, distribution deals, and strategic partnerships. It's a high-
              energy and transformative experience where creativity meets opportunity, propelling innovative
              projects from concept to reality and shaping the future of African cinema on a global scale.
            </p>
            <div className="bg-[#f7f4f0] p-4 rounded-lg mb-6">
              <p className="font-bold mb-2">Key Dates:</p>
              <p>Pitch Competition Starts: AUGUST 15TH, 2024</p>
              <p>Pitch Competition Ends: OCTOBER 5TH, 2024</p>
            </div>
            <button 
              onClick={() => setShowPitchForm(true)}
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
            >
              SUBMIT PITCH DOCUMENT
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showPitchForm && (
          <PitchSubmissionForm onClose={() => setShowPitchForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default PitchSessionsPage;